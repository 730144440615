import React, { useRef, useState } from 'react';
import "./Contacts.css";
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import emailjs from "emailjs-com";

const Contacts = () => {
  const form = useRef();
  const [messageStatus, setMessageStatus] = useState(""); // Para mostrar el estado del mensaje

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hp5hn3i', 'template_29ws585', form.current, 'z1P-cinpXODnxPBmB')
      .then((result) => {
        console.log(result.text);
        setMessageStatus("Message sent successfully!");
        e.target.reset();
      }, (error) => {
        console.log(error.text);
        setMessageStatus("An error occurred. Please try again.");
      });
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>kevinsanchezjuliao@gmail.com</h5>
            <a href="mailto:kevinsanchezjuliao@gmail.com" target="_blank" rel="noopener noreferrer">Send a message</a>
          </article>
          <article className='contact__option'>
            <RiMessengerLine className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>Kevin Sanchez Juliao</h5>
            <a href="https://m.me/xpclown" target="_blank" rel="noopener noreferrer">Send a message</a>
          </article>
          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>Tell me ¡Hi!</h5>
            <a href="https://wa.me/+573117726878?text=Hola-me-interesa-conocer-mas-sobre-ti" target="_blank" rel="noopener noreferrer">Send a message</a>
          </article>
        </div>

        {/* CONTACT FORM */}
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your E-Mail' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>

        {/* Message Status */}
        {messageStatus && <p className="message-status">{messageStatus}</p>}
      </div>
    </section>
  );
};

export default Contacts;