import React from 'react'
import "./portfolio.css"
import IMG1 from "../../assets/s1mple.png"
import IMG2 from "../../assets/portaf2.png"
import IMG3 from "../../assets/plataformasweb.png"
// import IMG4 from "../../assets/Live4.png"
// import IMG5 from "../../assets/Live5.png"
// import IMG6 from "../../assets/Live6.png"

const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className='portfolio__item'>
          <div className="portfolio_item-image">
            <img src={IMG1} alt="" />
          </div>
            <h3>S1mple T4p</h3>
            <div className="portfolio-item-cta">
              <a  className='btn disabled' >Github</a>
              <a href="https://s1mplet4p.com" className='btn btn-primary' target="_blank">Revisar</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio_item-image">
            <img src={IMG2} alt="" />
          </div>
            <h3>Grupo del Valle E-Commerce</h3>
            <div className="portfolio-item-cta">
              <a  className='btn'>Github</a>
              <a href="https://groupautomotrizdelvalle.com/" className='btn btn-primary' target="_blank">Ver Web</a>
            </div>
        </article>
        <article className='portfolio__item'>
          <div className="portfolio_item-image">
            <img src={IMG3} alt="" />
          </div>
            <h3>Plataforma de clientes</h3>
            <div className="portfolio-item-cta">
              <a  className='btn'>Github</a>
              <a href="https://plataforma.s1mplet4p.com" className='btn btn-primary' target="_blank">Live Demo</a>
            </div>
        </article>
        
      </div>
    </section>
  )
}

export default portfolio