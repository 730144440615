import React from 'react'
import {BsInstagram, BsLink, BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {BsDiscord} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/kevin-sanchez-j/" target="_blank"><BsLinkedin/></a>
        <a href="https://www.instagram.com/kevinsanchez2/" target="_blank"><BsInstagram/></a>
        <a href="https://s1mplet4p.com/portafolio" target="_blank"><BsLink/></a>
    </div>
  )
}

export default HeaderSocials