import React from 'react'
import "./testimonials.css"

import Avt1 from "../../assets/test1.jpg"
import Avt2 from "../../assets/test2.jpg"
import Avt3 from "../../assets/test3.png"
import Avt4 from "../../assets/test4.png"


// import Swiper core and required modules
import {  Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data=[
  {
    avatar: Avt1,
    name: "Juan Zuluaga",
    review: "Working with Kevin has been a game-changer for our business. His expertise in web development and his attention to detail helped us build a website that not only looks amazing but also performs flawlessly. Kevin was able to bring our vision to life and exceeded our expectations in every way."
  },
  {
    avatar: Avt2,
    name: "Margy Perez",
    review: "Kevin’s ability to create a custom, high-performance WordPress site was exactly what we needed. His SEO strategies improved our search rankings dramatically, and his technical skills ensured that everything worked seamlessly. I highly recommend Kevin for any web development project."
  },
  {
    avatar: Avt3,
    name: "Medardo Paredes",
    review: "Kevin is an exceptional full-stack developer who combines technical proficiency with a deep understanding of business needs. He worked closely with us to deliver a customized solution that helped streamline our operations and enhance user experience. I couldn’t be happier with the results!"
  }
  
]



const testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

     <Swiper className="container testimonials__container"
       // install Swiper modules
       modules={[ Pagination]}
              
       spaceBetween={40}
       slidesPerView={1}
       pagination={{ clickable: true }}
     >
       {
         data.map(({avatar,name,review},index)=>{
           return (
            <SwiperSlide key={index} className='testimonial'>
            <div className="client__avatar">
              <img src={avatar} alt="" />
            </div>
            <h5 className='client__name'>{name}</h5>
            <small className='client_review'>
            {review}
            </small>
          </SwiperSlide>
           )
         })
       }      
      </Swiper> 
    </section>
  )
}

export default testimonials