import React from 'react'
/* importar la CV */

const CTA = () => {
  return (
    <div className='cta'>
        <a href='https://wa.me/+573117726878?text=Hola-me-interesa-comprarte-un-cafe-para-tu-web' className='btn'>Buy me a coffe</a>
        <a href='#contact' className='btn btn-primary '>Let's Talk</a>


    </div>
  )
}

export default CTA