import React, { useState, useEffect } from 'react';
import "./Header.css";
import CTA from './CTA';
import ME from "../../assets/profile.png";
import ME2 from "../../assets/profile2.png";
import HeaderSocials from './HeaderSocials';

const Header = () => {
  const [currentImage, setCurrentImage] = useState(ME);
  const [hasScrolled, setHasScrolled] = useState(false);

  // Cambiar la imagen solo después del primer scroll
  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        setCurrentImage(ME2);
        setHasScrolled(true);

        // Volver a ME después de 2 segundos
        setTimeout(() => {
          setCurrentImage(ME);
        }, 2000);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasScrolled]);

  // Cambiar la imagen al pasar el mouse (si no ha hecho scroll aún)
  const handleMouseEnter = () => {
    if (!hasScrolled) {
      setCurrentImage(ME2);

      // Volver a ME después de 2 segundos
      setTimeout(() => {
        setCurrentImage(ME);
      }, 2000);
    }
  };

  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Kevin Sanchez</h1>
        <h5 className="text-light">Fullstack Developer</h5>

        <CTA />
        <HeaderSocials />

        <div className="me">
          <img
            src={currentImage}
            alt="me"
            onMouseEnter={handleMouseEnter}
          />
        </div>

        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
  );
};

export default Header;
