import React from 'react'
import Header from "./components/Header/Header"
import Nav from "./components/Nav/nav"
import About from "./components/About/About"
import Experience from "./components/Experience/experience"
import Services from "./components/Services/services"
import Portfolio from "./components/Portfolio/portfolio"
import Testimonials from "./components/Testimonials/testimonials"
import Contacts from "./components/Contacts/Contacts"
import Footer from "./components/Footer/Footer"

const App = () => {
  return (
    <>
        <Header />
        <Nav />
        <About />
        <Experience/>
        <Services/>
        <Portfolio/>
        <Testimonials/>
        <Contacts/>
        <Footer/>

    </>
  )
}

export default App