import React from 'react'
import "./About.css"
import ME from "../../assets/me-about.png"
import {FaAward} from "react-icons/fa"
import {FiUsers} from "react-icons/fi"
import {VscFolderLibrary} from "react-icons/vsc"

const About = () => {
  return (
    <section id = "about">
      <h5>Get to Know</h5>
      <h2>About Me</h2>

      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            
            <article className='about__card'>
              <FaAward className="about__icon"/>
              <h5>Experience</h5>
              <small>+5 Years Experienced</small>
            </article>

            <article className='about__card'>
              <FiUsers className="about__icon"/>
              <h5>Clients</h5>
              <small>+120 satisfied</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className="about__icon"/>
              <h5>Projects</h5>
              <small>+130 Completed</small>
            </article>
          </div>

          <p>
          With over 6 years of experience in software development, I have mastered various programming languages, enabling me to adapt to different technologies and challenges. I specialize in full-stack development and have extensive expertise in building customized, high-performing WordPress websites. My focus is on delivering efficient, well-designed solutions that help my clients achieve their goals effectively and exceed their expectations.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About