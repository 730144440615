import React from 'react'
import "./services.css"
import {BiCheck} from "react-icons/bi"

const services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className='service'>
          <div className="service__head">
            <h3>UWeb Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Responsive design optimized for mobile devices.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Full-stack programming using modern technologies.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Database integration and content management systems.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Advanced web security implementation.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Server deployment and hosting maintenance.</p>
            </li>
          </ul>
        </article>
{/*  UI/UX ENDD!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
        <article className='service'>
          <div className="service__head">
            <h3>SEO</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Keyword optimization and content strategy.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Site speed and performance enhancement.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Link building strategies and quality backlinks.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Website architecture audit and optimization.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>SEO metrics analysis and tracking with advanced tools.</p>
            </li>
          </ul>
        </article>
{/*  Web Development!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
        <article className='service'>
          <div className="service__head">
            <h3>Web customization</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Custom functionality integration with plugins or bespoke code.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Tailored design to meet client-specific needs.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Custom e-commerce solutions development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Personalized forms and user flows.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>API and third-party service integration for specialized functions.</p>
            </li>
          </ul>
        </article>
{/*  Content Creation ENDD!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}

      </div>
    </section>
  )
}

export default services